import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import axios from 'axios'
import baseURL from "@/main"
import router from "../router/index"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    showSuccess: false,
    showError: false,
    showMobileNav: false,
    user:{},
    errorMsg: 'Error',
    successMsg: 'Success',
    
  },
  mutations: {
    showMobileNav(state, val){
      state.showMobileNav = val
    },
    startLoading(state){
      state.loading = true
    },
    endLoading(state){
      state.loading = false
    },
    setSuccess(state, payload){
      state.showSuccess = payload.status
      state.successMsg = payload.msg
    },
    setError(state, payload){
      state.showError = payload.status
      state.errorMsg = payload.msg
    },
    setUser(state, payload){
      state.user = payload
    },
    logoutUser(state){
      state.user = {}
    }
  },
  actions: {
    loginUser({commit}, user){
      return new Promise((resolve, reject)=> {
        axios({url: `${baseURL}/app/user/login`, data: user, method: 'POST'})
        .then((res)=>{
          commit('setUser', res.data.data)
           //Store the token in localstorage
          const token = res.data.data.token
          localStorage.setItem('user-token', token) 
           //Set the authorization header for future API calls
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
          resolve(res)
        })
        .catch((err)=>{
          commit('endLoading')
          reject(err.response)
        })
      })
    },
    logoutUser({commit}){
      return new Promise((resolve)=>{
        setTimeout(()=> {
          commit('logoutUser')
          localStorage.removeItem('user-token')
          // remove the axios default header
          delete axios.defaults.headers.common['Authorization']
          resolve()
        }, 2000)
      })
    },
    handleError({commit, dispatch}, err){
      // console.log(err.response.data.message)
      if(err.response.data.message == "Invalid or expired JWT" || err.response.data.message == "Missing or malformed JWT"){
        commit('setError', {status: true, msg: 'Session expired'})
        commit('endLoading')
        dispatch('logoutUser')
        router.push('/')
      }else if(err.response.data.message == "Permission denied!"){
        commit('setError', {status: true, msg: 'Permission denied!'})
        commit('endLoading')
        dispatch('logoutUser')
        router.push('/')
      }else if(err.response.data.message){
        commit('endLoading')
        commit('setError', {status: true, msg: err.response.data.message})
      }else{
        commit('endLoading')
        commit('setError', {status: true, msg: "An error occured"})
      }
    }
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
