import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from "../store/index"
Vue.use(VueRouter)

export default [
    {
        path: '/user',
        component: () => import('../views/User/User'),
        // beforeEnter : (to, from , next)=>{
        //     const token = localStorage.getItem('user-token')
        //     if(to.name !== 'Home' && !token && store.state.user.role !== 'user') next({name: 'Home'})
        //     else next()
        // }
    }
]