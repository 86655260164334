import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import userroutes from './userroutes'
// import store from "../store/index"
Vue.use(VueRouter)

const baseroutes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword.vue')
  },
  {
    path: '/app',
    redirect: '/app/dashboard',
    component: () => import('../views/Dashboard'),
    // beforeEnter : (to, from , next)=>{
    //   const token = localStorage.getItem('user-token')
    //   if(to.name !== 'Home' && !token && store.state.user.role !== 'admin') next({name: 'Home'})
    //   else next()
    // },
    children: [
      {
        path: '/app/dashboard',
        redirect: '/app/dashboard/home',
        component: () => import('../views/Menu/Menu'),
        // beforeEnter : (to, from , next)=>{
        //   const token = localStorage.getItem('user-token')
        //   if(to.name !== 'Home' && !token && store.state.user.role !== 'admin') next({name: 'Home'})
        //   else next()
        // },
        children: [
          {
            path: '/app/dashboard/home',
            name: 'Menu',
            component: () => import('../views/Menu/Home')
          },
          {
            path: '/app/dashboard/upload',
            name: 'Upload',
            component: () => import('../views/Menu/Upload')
          },
          {
            path: '/app/dashboard/viewpolicies',
            name: 'ViewPolicies',
            component: () => import('../views/Menu/ViewPolicies')
          },
          {
            path: '/app/dashboard/claim/:id',
            name: 'ViewClaim',
            component: () => import('../views/Menu/ViewClaim')
          },
        ]
      }
    ]
  },
]
const routes = baseroutes.concat(userroutes)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
